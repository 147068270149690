// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// ng serve --configuration=dev
// ng build --configuration=dev

export const environment = {
  production: false,
  companyName:"CMT Logistics",
  slogan:"Zur richtigen Zeit.Am richtigen Ort.",
  gatewayApiUrl:"https://flptest.parklabtechnologie.com",
  graphqlUrl:"https://flptest-aggregator-service.parklabtechnologie.com/graphql",
  flpUrl:"https://cmt.parklab.app",
  leadShiftUrl:"https://leadshift-demo.parklab.app",
  countbookUrl:"https://lager-demo.parklab.app"
};

